'use strict';

class JavaScript {
    constructor() {
        this.headerNavbarSelector = 'nav.navbar';

        this.backgroundLoader();
        this.navigationResponsiveMenu();
        this.lazyImages();
    }

    backgroundLoader() {
        let bgOverlay = document.querySelector('.bg-overlay.post-header');
        if (bgOverlay) {
            let originalImage = bgOverlay.getAttribute('data-original');
            if (originalImage && originalImage !== '') {
                let style = document.createElement('style');
                style.innerHTML =
                    '.post-header-enhanced {' +
                    '    background: url(\'' + originalImage + '\') no-repeat center center fixed;' +
                    '}';
                let ref = document.querySelector('head');
                ref.appendChild(style);

                let bgImage = new Image();
                bgImage.onload = function () {
                    bgOverlay.classList.add('post-header-enhanced');
                };
                bgImage.src = originalImage;
            }
        }
    }

    navigationResponsiveMenu() {
        // Closes the responsive menu on menu item click
        $(this.headerNavbarSelector + ' .navbar-collapse a').click(function () {
            $(this.headerNavbarSelector + ' .navbar-toggler:visible').click();
        });
    }

    lazyImages() {
        $('.lazy').Lazy({
            scrollDirection: 'vertical',
            effect: 'fadeIn',
            threshold: 400,
            onError: function (element) {
                console.log('Error lazy loading ' + element.attr('id') + ' ' + element.data('src'));
            }
        });
    }
}

jQuery(function($) {
    new JavaScript();
});
